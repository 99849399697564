var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isShowLoading}},[_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveData($event)}}},[_c('b-card',{attrs:{"title":_vm.$t('work.headerJSA')}},[_c('b-row',{staticClass:"mb-2",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.workType')}},[_c('b-form-input',{attrs:{"plaintext":""},model:{value:(_vm.workTypeName),callback:function ($$v) {_vm.workTypeName=$$v},expression:"workTypeName"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addSafetyRow}},[_vm._v(_vm._s(_vm.$t('work.btnAddJSA')))])],1)],1)],1),_vm._l((_vm.jsaLists),function(row,key){return _c('div',{key:'workDay-' + key,staticClass:"multiple-row"},[_c('b-row',[_c('b-col',[_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('work.jobStep'))+" "+_vm._s(row.jsaNum))])]),_c('b-col',{staticClass:"text-right"},[(_vm.jsaLists.length > 1)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteSafetyRow(key)}}},[_vm._v(" "+_vm._s(_vm.$t('general.btnDelete'))+" ")]):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.jobDescription') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.jobDescription'),"rules":"required","vid":'jobDescription' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"jobDescriptionName","options":_vm.optionJobStepLists,"reduce":function (option) { return option.jobDescriptionId; }},model:{value:(row.jsaStepId),callback:function ($$v) {_vm.$set(row, "jsaStepId", $$v)},expression:"row.jsaStepId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(row.jsaStepId === 9999)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.jobOther') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.jobOther'),"rules":"required","vid":'jobOther' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(row.jsaJobDescription),callback:function ($$v) {_vm.$set(row, "jsaJobDescription", $$v)},expression:"row.jsaJobDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.identifyHazards') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.identifyHazards'),"rules":"required","vid":'identifyHazards' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(row.jsaIdentifyHazards),callback:function ($$v) {_vm.$set(row, "jsaIdentifyHazards", $$v)},expression:"row.jsaIdentifyHazards"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.preventiveMeasures') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.preventiveMeasures'),"rules":"required","vid":'preventiveMeasures' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(row.jsaPreventiveControl),callback:function ($$v) {_vm.$set(row, "jsaPreventiveControl", $$v)},expression:"row.jsaPreventiveControl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.attachFile')}},[(row.jsaAttach)?_c('thumbnail-file',{attrs:{"src":row.jsaAttach}}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('work.attachFile'),"vid":'attachmentFile' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":".jpg, .gif, .pdf, .png"},model:{value:(row.attachmentFile),callback:function ($$v) {_vm.$set(row, "attachmentFile", $$v)},expression:"row.attachmentFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"jsa-status-radio"},[_c('b-form-group',[_c('b-form-radio-group',{model:{value:(row.jsaStatus),callback:function ($$v) {_vm.$set(row, "jsaStatus", $$v)},expression:"row.jsaStatus"}},[_c('b-form-radio',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t('work.onPlan')))]),_c('b-form-radio',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t('work.offPlan')))])],1)],1)],1)])],1)],1)})],2),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"success","disabled":_vm.isBtnDisabled}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.btnSubmit'))+" ")],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }