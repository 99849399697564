<template>
  <div>
    <b-overlay :show="isShowLoading">
      <validation-observer ref="validateForm" #default="{invalid}">
        <b-form @submit.prevent="saveData">

          <b-card :title="$t('work.headerJSA')">
            <b-row class="mb-2" align-v="center">
              <b-col md="6">
                <b-form-group :label="$t('work.workType')">
                  <b-form-input v-model="workTypeName" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-col class="text-right">
                  <b-button variant="primary" @click="addSafetyRow">{{ $t('work.btnAddJSA') }}</b-button>
                </b-col>
              </b-col>
            </b-row>

            <div class="multiple-row" v-for="(row, key) in jsaLists" :key="'workDay-' + key">
              <b-row>
                <b-col>
                  <h5 class="mb-1">{{ $t('work.jobStep') }} {{ row.jsaNum }}</h5>
                </b-col>

                <b-col class="text-right">
                  <b-button variant="danger" size="sm" @click="deleteSafetyRow(key)" v-if="jsaLists.length > 1">
                    {{ $t('general.btnDelete') }}
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group :label="$t('work.jobDescription') + '*'">
                    <validation-provider #default="{ errors }" :name="$t('work.jobDescription')" rules="required" :vid="'jobDescription' + key">
                      <v-select v-model="row.jsaStepId" label="jobDescriptionName"
                                :options="optionJobStepLists" :reduce="option => option.jobDescriptionId"
                                :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6" v-if="row.jsaStepId === 9999">
                  <b-form-group :label="$t('work.jobOther') + '*'">
                    <validation-provider #default="{ errors }" :name="$t('work.jobOther')" rules="required" :vid="'jobOther' + key">
                      <b-form-input v-model="row.jsaJobDescription" :state="errors.length > 0 ? false : null"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group :label="$t('work.identifyHazards') + '*'">
                    <validation-provider #default="{ errors }" :name="$t('work.identifyHazards')" rules="required" :vid="'identifyHazards' + key">
                      <b-form-input v-model="row.jsaIdentifyHazards" :state="errors.length > 0 ? false : null"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group :label="$t('work.preventiveMeasures') + '*'">
                    <validation-provider #default="{ errors }" :name="$t('work.preventiveMeasures')" rules="required" :vid="'preventiveMeasures' + key">
                      <b-form-input v-model="row.jsaPreventiveControl" :state="errors.length > 0 ? false : null"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group :label="$t('work.attachFile')">
                    <thumbnail-file :src="row.jsaAttach" v-if="row.jsaAttach"/>
                    <validation-provider #default="{ errors }" :name="$t('work.attachFile')" :vid="'attachmentFile' + key">
                      <b-form-file v-model="row.attachmentFile" accept=".jpg, .gif, .pdf, .png"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <div class="jsa-status-radio">
                    <b-form-group>
                      <b-form-radio-group v-model="row.jsaStatus">
                        <b-form-radio value="1">{{ $t('work.onPlan') }}</b-form-radio>
                        <b-form-radio value="2">{{ $t('work.offPlan') }}</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </div>

                </b-col>
              </b-row>
            </div>

          </b-card>

          <b-row>
            <b-col class="text-right">
              <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                <b-spinner small v-if="isBtnDisabled"/>
                <feather-icon icon="SaveIcon" v-else/>
                {{ $t('general.btnSubmit') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BFormCheckbox, BFormFile, BButton, BOverlay, BFormRadio, BFormRadioGroup} from 'bootstrap-vue'
import {required} from '@validations'
import tableMixins from "@/mixins/tableMixins"
import masterMixins from "@/mixins/masterMixins"
import formMixins from "@/mixins/formMixins"
import {WorkService, FileStorageService} from "@/services"

const workService = new WorkService()
const fileStorageService = new FileStorageService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormFile,
    BButton,
    BSpinner,
    BOverlay,
    BFormRadio,
    BFormRadioGroup,
  },
  mixins: [tableMixins, masterMixins, formMixins],
  async created() {
    this.isShowLoading = false
    this.id = this.$route.params.id
    this.step1 = this.$store.state.work.step1
    this.workTypeDetail = this.$store.getters['work/getWorkTypeDetail'](this.step1.workTypeId)

    this.optionJobStepLists = []
    this.workTypeDetail.map(data => {
      data.jobDescriptionLists.map(item => this.optionJobStepLists.push(item))
    })

    this.optionJobStepLists.push({
      jobDescriptionId: 9999,
      jobDescriptionName: 'Other'
    })
    if (this.id) {
      await this.getJsaData()
    } else {
      this.optionJobStepLists.filter(data => data.jobDescriptionId !== 9999).map(data => {
        this.addSafetyRow(data.jobDescriptionId)
      })
    }
  },
  beforeDestroy() {
    // this.$store.commit('work/setStep1', {})
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    step1: {},
    workTypeDetail: [],
    jsaLists: [],
    optionJobStepLists: []
  }),
  computed: {
    showAsterisk() {
      return this.id ? '' : '*'
    },
    workTypeName() {
      let results = this.workTypeDetail.map(data => data.workTypeName)
      return results.join(', ')
    }
  },
  methods: {
    async getJsaData() {
      workService.listJsaData(this.id).then(queryResult => {
        let {isSuccess, data} = queryResult
        if (isSuccess) {
          this.jsaLists = data.results.map(result => {
            return {
              jsaNum: result.jsaNum,
              jsaStepId: result.jobDescId,
              jsaJobDescription: result.jsaJobDescription,
              jsaIdentifyHazards: result.jsaIdentifyHazards,
              jsaPreventiveControl: result.jsaPreventiveControl,
              attachmentFile: [],
              jsaAttach: result.jsaAttach,
              jsaStatus: result.jsaStatusId,
              workJsaId: result.workJsaId
            }
          })
        } else {
          this.$store.commit('main/setToastError', data)
        }
        this.isShowLoading = false
      })
    },
    async saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let isUploadError = false
          let uploadErrorMessage = ''
          let jsaActions = []
          for (const item of this.jsaLists) {
            if (Object.entries(item.attachmentFile).length > 0) {
              let uploadResult = await fileStorageService.upload(item.attachmentFile)
              if (uploadResult.isSuccess) {
                jsaActions.push({
                  ...item,
                  jsaAttachId: uploadResult.fileId
                })
              } else {
                isUploadError = true
                uploadErrorMessage = uploadResult.message
              }
            } else {
              jsaActions.push({
                ...item,
              })
            }
          }

          if (isUploadError) {
            this.$store.commit('main/setToastError', {message: uploadErrorMessage})
            this.isBtnDisabled = false
            return false
          }

          let actionData = {
            ...this.$store.state.work.step1,
            jsaLists: jsaActions,
          }

          // let {isSuccess, data} = await workService.createData(actionData)
          let saveResult
          if(this.id) {
            saveResult = await workService.updateData(this.id, actionData)
          } else {
            saveResult = await workService.createData(actionData)
          }

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            this.$store.commit('work/setCreateSuccess', saveResult.data.result)
            this.$router.replace({name: 'pageWorkAddSuccess'})
          } else {
            this.$store.commit('main/setToastError', saveResult.data)

            if(saveResult.data.errorCode === 'date-error') {
              this.$router.replace({name: 'pageWorkAddStep1'})
            }
          }
          this.isBtnDisabled = false
        }
      })
    },
    addSafetyRow(defaultStep = '') {
      this.jsaLists.push({
        jsaNum: this.jsaLists.length + 1,
        jsaStepId: typeof defaultStep === 'number' ? defaultStep : '',
        jsaJobDescription: '',
        jsaIdentifyHazards: '',
        jsaPreventiveControl: '',
        attachmentFile: [],
        jsaAttach: '',
        jsaStatus: '1',
        workJsaId: '',
      })
    },
    deleteSafetyRow(row) {
      this.jsaLists.splice(row, 1)
      this.jsaLists = this.jsaLists.map((data, key) => ({...data, jsaNum: key + 1}))
    },
  }
}
</script>

<style lang="scss">
.jsa-status-radio {
  height: 100%;
  display: flex;
  align-items: end;
  padding-bottom: 10px;
}
</style>
